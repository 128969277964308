@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

.layout {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  line-height: 1.5;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background: skyblue url(/static/media/background.b96d69d9.png) no-repeat fixed center center;
  background-size: cover;
}
* {
  box-sizing: border-box;
}
.profile-pic {
  border-radius: 50%;
  width: 150px;
  height: 150px;
}
img {
  margin: 0;
}
.leaders {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.pub-header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1050;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.05);
}
.pub-inner {
  position: relative;
  height: 44px;
  padding: 0 20px;
  max-width: 1200px;
}
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 100%;
}
.grow-flexible {
  -webkit-flex-grow:1;
          flex-grow:1;
}
.grow-flexible-mobile {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
.header-col-left {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  float: left;
}
.header-col-middle {
  visibility: hidden;
  height: 0;
  display: -webkit-flex;
  display: flex;
  float: left;
}
.header-col-left-inner {
  display: -webkit-flex;
  display: flex;
  float: left;
}
.logo-size {
  width: 86px;
  height: 86px;
}
.nav-item {
  display: inherit;
  margin-left: 1rem;
  margin-top: 1rem;
  -webkit-align-items: center;
          align-items: center;
  transition: all 0.25s;
  height: 100%;
  color: inherit;
  position: relative;
  text-decoration: none;
}
.selectLanguage {
  width: 150px;
}
.d-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.flex-items-xxs-mid {
  -webkit-align-items: center;
          align-items: center;
}
.flex-items-xxs-r {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.m-l-lg {
  margin-left: 3rem;
}
#content {
  padding-top: 65px;
}
.main {
  display: block;
}
.side-menu-backdrop {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0;
  height: 100%;
}
.side-menu {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  transition: all 0s ease-in-out;
  height: 100%;
  background-color: #f5f7fa;
  padding-top: 62px;
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 0;
  width: 216px;
}
.no-active-tab  {
  width: 33px;
}
.scrollable-container {
  overflow-x: hidden;
  overflow-y: auto;
}
.p-t-xs {
  padding-top: 1rem;
}
.content-body {
  width: 100%;
}
.mr {
  margin-right: 2rem;
}
.ml {
  margin-left: 2rem;
}
.pg-body {
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
}
.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.card {
  background: #fff;
  margin-bottom: 2rem;
} 
.card-lg {
  padding: 4rem;
}
section {
  display: block;
}
.text-center {
  text-align: center;
}
.m-b {
  margin-bottom: 2rem;
}
.font-w {
  font-size: 35px;
  line-height: 1.25;
}
.text-justify {
  text-align: justify;
}
.mx-auto {
  margin-left: auto;
}
.m-x-w {
  max-width: 75rem;
}
.img-fluid {
  display: block;
  max-width: 100%;
  height: auto;
}
.m-x-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.mb-xxl {
  margin-bottom: 5rem;
}
.text-xxxs {
  font-size: 15px;
}
.mb-sm {
  margin-bottom: 2rem;
}
.t-up {
  text-transform: uppercase;
}
.t-md {
  font-size: 25px;
}
.t-b {
  font-weight: 700;
}
.text-xs {
  font-size: 16px;
  max-width: 33rem;
}
.underline {
  position:relative;
  color: #000;
  text-decoration: none;
}
.underline:hover {
  color: #000;
}
.underline:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: black;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s ;
}
.underline:hover:before{
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.header-col-right {
  display: inline-block;
  position: relative;
  float: right;
  text-align: right;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: lightblue;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  right: 0;
}
.menu-img {
  visibility: hidden;
}
.button-div {
  right: 0;
  text-align: right;
  float: right;
  width: 160px;
  margin-top: -20px;
  margin-bottom: 20px;
}
.dropbtn {
  background-color: white;
  color: black;
  padding: 12px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.dropdown-content p {
  color: black;
  padding: 20px;
  text-decoration: none;
  display: block;
  text-align: center;
}
.button-div:hover .dropdown-content {
  display: block;
}
.button-div:focus .dropdown-content {
  display: block;
    -moz-outline-style: none;
}
.dropbtn:hover {
  background: lightcyan;
}
.dropdown-content p:hover {
  background: lightgray;
}

.youtube-video-con {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}
.youtube-video-con iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.logo-size-mid {
  visibility: hidden;
  width: 84px;
  height: 84px;
  position: absolute;
}

[class*="col-"] {
  width: 100%;
}

@media only screen and (max-width: 750px) {
  .logo-size {
    visibility: hidden;
  }
  .upper-mobile {
    position: relative;
    display: inline-block;
    width: 84px;
  }
  .mobile-links {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 3;
  }
  .mobile-links a {
    color: black;
    display: block;
    text-decoration: none;
    text-align: left;
    margin: 10px;
    padding: 20px;
    font-size: 12px;
  }
  .upper-mobile:hover .mobile-links {
    display: block;
    width: 84px;
  }
  .upper-mobile:focus .mobile-links {
    display: block;
    width: 84px;
  }
  .header-col-middle {
    visibility: visible;
    -webkit-align-items: center;
            align-items: center;
    height: 1px;
    z-index: -1;
  }
  .header-col-middle img {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: block;
    visibility: visible;
    width: 96px;
    height: 96px;
    z-index: 50;
  }
  .menu-img {
    visibility: visible;
    position: relative;
    margin-left: -75px;
    margin-bottom: 26px;
    width: 34px;
    height: 34px;
    z-index: 50;
  }
}

@media only screen and (min-width: 600px) {
  .col-sm-6 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-3 {
    width: 100%;
  }
  .col-lg-10 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
    .youtube-video-con {
        width: auto;
        position: relative;
        height: 400px;
        max-height: 500px;
    }
  .layout {
      background: skyblue url(/static/media/background.b96d69d9.png) no-repeat fixed center center;
      background-size: cover;
  }
}
@media only screen and (min-width: 1200px) {
  .col-sm-6 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-3 {
    width: 100%;
  }
  .col-lg-10 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
}

.dropdown-css {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
}
